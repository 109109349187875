import PersonIcon from "@mui/icons-material/Person";
import SearchIcon from "@mui/icons-material/Search";
import {
  AppBar,
  Avatar,
  Box,
  IconButton,
  InputBase,
  Toolbar,
  Typography,
} from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
import * as React from "react";
import { useState } from "react";
import { FaChevronDown } from "react-icons/fa";
import { HiMenuAlt2 } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import debounce from "../../lib/debounce";
import { getCountryCategoryEvents } from "../../services/data/events";
import { selectCountries } from "../../store/slice/countries";
import { getEvents } from "../../store/slice/events";
import { updateLoader } from "../../store/slice/loading";
import { selectUser } from "../../store/slice/user";
import { IMAGES } from "../../theme";
import CustomButton from "../CustomButton";
import CustomDrawer from "../CustomDrawer";
import CountryMenu from "../DropDowns/CountryMenu";
import LanguageMenu from "../DropDowns/LanguageMenu";
import MainMenu from "../DropDowns/MainMenu";
import MobileNumberModal from "../Modals/MobileNumber";
import Profile from "../Modals/Profile";
import { Style } from "./style";
import { changeLanguage, i18n } from "../index";
import { useTranslation } from "react-i18next";
import { MdLanguage } from "react-icons/md";
import { IoLocationSharp } from "react-icons/io5";

const Navbar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const countries = useSelector(selectCountries);
  const [open, setOpen] = useState(false);
  const [openProfile, setOpenProfile] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const [countryMenuAnchorEl, setCountryMenuAnchorEl] = useState(null);
  const [languageMenuAnchorEl, setLanguageMenuAnchorEl] = useState(null);
  const [searchKey, setSearchKey] = useState("");
  const isHome = useLocation()?.pathname == "/";
  const { t } = useTranslation();

  let { language, countryId, category } = user;
  const isEmployee = localStorage.getItem("isEmployee");
  const handleLanguageMenuClose = () => {
    setLanguageMenuAnchorEl(null);
    let tempLanguage = localStorage.getItem("language");
    changeLanguage(tempLanguage);
  };
  const handleLanguageMenuOpen = (event) => {
    setLanguageMenuAnchorEl(event.currentTarget);
  };

  const openLink = () => {
    let url = "https://admin.o7events.com/vendor";
    // window.open("","_blank")
    window.open(url, '_blank', 'noopener,noreferrer')
  };

  const handleCountryMenuClose = () => {
    setCountryMenuAnchorEl(null);
  };
  const handleCountryMenuOpen = (event) => {
    setCountryMenuAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };
  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };
  const countryMenuId = "primary-search-country-menu";
  const mobileMenuId = "primary-search-account-menu-mobile";
  const langugeMenuId = "primary-language-menu";

  const countryFlag = countries.find(
    (item) => item?.id === user?.countryId
  )?.full_flag;
  const handleSearchResult = async (text) => {
    const res = await getCountryCategoryEvents({
      searchKey: text,
      page: 1,
      number: 12,
      country: countryId,
      category: category.id,
    });
    dispatch(getEvents({ ...res?.data?.data, searchKey: text }));
    dispatch(updateLoader({ loader: false }));
  };
  const handleSearchChange = (e) => {
    setSearchKey(e.target.value);
    dispatch(updateLoader({ loader: true }));
    debounce(() => handleSearchResult(e.target.value), 1500);
  };
  const isArabic = language === "ar";

  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "inherit",
    "& .MuiInputBase-input": {
      fontFamily: isArabic ? "Cairo, sans-serif" : "Inter",
      textAlign: isArabic ? "end" : "start",
      padding: theme.spacing(1, 5.5, 1, 5.5),
      // paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("md")]: {
        width: "20ch",
      },
    },
  }));

  return (
    <Box sx={Style.main(isHome, isEmployee)}>
      <AppBar position="static">
        <Toolbar
          sx={{
            display: "flex",
            flexDirection: isArabic ? "row-reverse" : "row",
            justifyContent: "space-between",
          }}
        >
          <Box display="flex" flexDirection={isArabic ? "row-reverse" : "row"}>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={() => setOpenDrawer(true)}
              sx={{ mr: { xs: 0, md: 2 } }}
            >
              <HiMenuAlt2 style={Style.menuIcon} />
            </IconButton>
            <CustomDrawer
              setOpenProfile={setOpenProfile}
              openDrawer={openDrawer}
              setOpenDrawer={setOpenDrawer}
            />
            <Box
              component={"div"}
              onClick={() => navigate("/")}
              sx={Style.logoContainer(isArabic)}
            >
              <Box component={"img"} src={IMAGES.Logo} width={"100%"} />
            </Box>
          </Box>
          <Box sx={Style.searchBarContainer}>
            <Box sx={Style.searchBar}>
              <Search
                sx={{
                  borderRadius: 10,
                  height: "50px",
                  display: "flex",
                  flexDirection: isArabic ? "row-reverse" : "row",
                }}
              >
                <SearchIconWrapper>
                  <SearchIcon sx={Style.searchIcon} />
                </SearchIconWrapper>
                <StyledInputBase
                  placeholder={t("navbar.input")}
                  inputProps={{ "aria-label": "search" }}
                  sx={Style.input}
                  value={searchKey}
                  onChange={handleSearchChange}
                />
              </Search>
            </Box>
          </Box>
          <Box sx={Style.optionsContainer(user, isArabic)}>
            <Box
              sx={{
                display: "inline-flex",
                alignItems: "center",
                gap: { md: "4px", sm: "10px" },
              }}
            >
              <Box>
                <IoLocationSharp
                  style={{
                    width: "36px",
                    height: "36px",
                  }}
                />
              </Box>

              <Typography
                sx={{
                  fontFamily: isArabic ? "Cairo, sans-serif" : "Inter",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: "500",
                  lineHeight: "normal",
                  paddingRight: "6px",
                  whiteSpace: "nowrap",
                }}
              >
                {t("navbar.location")}
              </Typography>
              <Avatar alt="Country" src={countryFlag} sx={Style.langCountry} />
              <IconButton
                size="small"
                aria-label="show more"
                aria-controls={countryMenuId}
                onClick={handleCountryMenuOpen}
                aria-haspopup="true"
                color="inherit"
              >
                <FaChevronDown />
              </IconButton>
            </Box>
            <Box
              sx={Style.langName(isArabic)}
              onClick={handleLanguageMenuOpen}
              aria-controls={langugeMenuId}
            >
              {language}
            </Box>
            <CustomButton
                onClick={() => openLink()}
                sx={Style.loginButtonSmall(isArabic)}
                buttonText={t("navbar.createEvent")}
                color="secondary"
              />
            {!user.name ? (
              <CustomButton
                onClick={() => setOpen(true)}
                sx={Style.loginButton(isArabic)}
                buttonText={t("navbar.login")}
                color="secondary"
              />
            ) : (
              <Box sx={Style.langName(isArabic)} onClick={handleMobileMenuOpen}>
                <PersonIcon style={Style.avatar} />
              </Box>
            )}
          </Box>
          <Box sx={Style.mobOptionsContainer}>
            <Box sx={{ display: "flex", gap: "4px" }}>
              <Box
                sx={{
                  display: "inline-flex",
                  alignItems: "center",
                  gap: "4px",
                }}
              >
                <Box>
                  <IoLocationSharp
                    style={{
                      width: "30px",
                      height: "30px",
                    }}
                  />
                </Box>

                <Typography
                  sx={{
                    display: { xs: "none", sm: "block" },
                    fontFamily: isArabic ? "Cairo, sans-serif" : "Inter",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: "500",
                    lineHeight: "normal",
                    paddingRight: "6px",
                    whiteSpace: "nowrap",
                  }}
                >
                  {t("navbar.location")}
                </Typography>
              </Box>
              <Avatar alt="Country" src={countryFlag} />
              <IconButton
                size="small"
                aria-label="show more"
                aria-controls={countryMenuId}
                aria-haspopup="true"
                onClick={handleCountryMenuOpen}
                color="inherit"
              >
                <FaChevronDown />
              </IconButton>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <CountryMenu
        id={countryMenuId}
        onClick={handleCountryMenuClose}
        onClose={handleCountryMenuClose}
        anchorEl={countryMenuAnchorEl}
      />
      <MainMenu
        anchorEl={mobileMoreAnchorEl}
        id={mobileMenuId}
        onClick={handleMobileMenuClose}
        onClose={handleMobileMenuClose}
        setOpenProfile={setOpenProfile}
      />
      <LanguageMenu
        anchorEl={languageMenuAnchorEl}
        id={langugeMenuId}
        onClick={handleLanguageMenuClose}
        onClose={handleLanguageMenuClose}
      />
      <Profile open={openProfile} setOpen={setOpenProfile} />
      <MobileNumberModal open={open} setOpen={setOpen} />
    </Box>
  );
};

export default Navbar;

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));
