import { Box, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { scanQrCode } from "../../services/redeem";
import { useDispatch, useSelector } from "react-redux";
import { logout, selectUser } from "../../store/slice/user";
import { IMAGES } from "../../theme";
import { CustomButton } from "../../components";
import { useTranslation } from "react-i18next";
import { logoutUser } from "../../services/profile";
import toast from "react-hot-toast";
import { Style } from "../../components/TicketStatus/Style";
import { QrReader } from "react-qr-reader";
import { CustomStyle } from "./style";
// import mixpanel from "mixpanel-browser";

const RedeemTickets = () => {
  const user = useSelector(selectUser);
  const delay = 1000;
  const { token, language } = useSelector(selectUser);
  const { t } = useTranslation();

  const [result, setResult] = useState(false);
  const [scanned, setScanned] = useState(false);
  const [error, setError] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [wait, setWait] = useState(false);
  window.addEventListener("resize", () => setWidth(window.innerWidth));
  const dispatch = useDispatch();
  const handleScan = (result) => {
    if (result) {
      scanQrCode({ code: result }, token)
        .then((res) => {
          setResult(res?.data);
          setScanned(false);
          setWait(true);
          setTimeout(() => {
            setWait(false);
          }, 2000);
        })
        .catch((err) => {
          console.log("err: ", err);
          setWait(false);
          setScanned(false);
          setResult(false);
        });
    }
  };

  const handleLogout = async () => {
    try {
      await logoutUser(token);
      dispatch(logout());
      toast.success("Sign Out Successfully");
    } catch (err) {
      return err;
    }
  };

  const isArabic = language === "ar";
  // useEffect(() => {
  //   mixpanel?.track("redeem-tickets", {
  //     email: user?.email,
  //     name: user?.name || "anonymous",
  //   });
  // }, []);

  return (
    <>
      <Box sx={CustomStyle.buttonBox}>
        <CustomButton
          sx={CustomStyle.button}
          onClick={handleLogout}
          buttonText={t("drawer.sign_out")}
          color={"secondary"}
        />
      </Box>
      <Box sx={CustomStyle.main}>
        {scanned ? (
          <Box
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            gap={"20px"}
          >
            <Typography
              p={"0 10px"}
              boxSizing={"border-box"}
              sx={Style.message(isArabic)}
            >
              <Typography
                component={"span"}
                sx={{
                  color:
                    !!error || result?.redeem
                      ? "rgba(255, 90, 90, 0.8)"
                      : "#fff",
                  fontSize: "20px",
                }}
              >
                Waiting ...
              </Typography>
            </Typography>
          </Box>
        ) : !wait ? (
          <QrReader
            delay={delay}
            constraints={{ facingMode: "environment" }}
            facingMode={"environment"}
            onResult={(result) => {
              if (!!result) {
                setError(false);
                setScanned(true);
                handleScan(result?.text);
              }
            }}
          />
        ) : (
          <Box
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            gap={"20px"}
          >
            <Box
              component={"img"}
              src={
                !!result && !result?.redeem ? IMAGES.success : IMAGES.failure
              }
              width={140}
              height={140}
            />
            <Typography
              p={"0 10px"}
              boxSizing={"border-box"}
              sx={Style.message(isArabic)}
            >
              <Typography
                component={"span"}
                sx={{
                  color:
                    !!error || result?.redeem
                      ? "rgba(255, 90, 90, 0.8)"
                      : "#fff",
                  fontSize: "20px",
                }}
              >
                {!!result
                  ? result?.redeem
                    ? "Already redeemed"
                    : "Redeem successful"
                  : !!error
                  ? `${error}`
                  : "Redeem Failed"}
              </Typography>
            </Typography>
          </Box>
        )}{" "}
      </Box>
    </>
  );
};

export default RedeemTickets;
